<style>
	#dv-smalltunnel{
		position: absolute;
		left: 0;
		top: 0;
		width: 100px;
		height: 100px;
		background-size: 100% auto;
		background-position: left top;
		background-repeat: no-repeat;
	}
	
	#dv-smalltunnel .viewbox{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		overflow-x: hidden;
	}
	
	#dv-smalltunnel .viewbox .defectbox{
		position: absolute;
		left: 0;
		top: 0;
	}
	#dv-smalltunnel .viewbox .defectbox .defectLine{
		position: absolute;
		left: 50px;
		top: 10px;
	}
	#dv-smalltunnel .viewbox .defectbox .defecticons{
		position: absolute;
		left: 0px;
		top: 2px;
	}
	#dv-smalltunnel .viewbox .defectbox .defecticons .iconbg{
		position: absolute;
		left: 0;
		top: 10px;
	}
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon1 { position: absolute; left: 12px; top: 6px; }   /* 拱顶 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon2 { position: absolute; left: 3px; top: 13px; }   /* 左侧拱顶 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon3 { position: absolute; left: 0px; top: 23px; }   /* 左侧拱腰 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon4 { position: absolute; left: 21px; top: 14px; }  /* 右侧拱顶 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon5 { position: absolute; left: 26px; top: 25px; }  /* 右侧拱腰 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon6 { position: absolute; left: -3px; top: 50px; }  /* 左侧边墙 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon7 { position: absolute; left: 30px; top: 50px; }  /* 右侧边墙 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon8 { position: absolute; left: 2px; top: 87px; }   /* 左侧隧底 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon9 { position: absolute; left: 13px; top: 87px; }  /* 隧底 */
	#dv-smalltunnel .viewbox .defectbox .defecticons .icon10{ position: absolute; left: 24px; top: 87px; }  /* 右侧隧底 */
	
	#dv-smalltunnel .viewbox .codebox{
		position: absolute;
		left: 0;
		bottom: 0;
		font-size: 12px;
	}
	#dv-smalltunnel .viewbox .codebox .item{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 80px;
		text-align: center;
	}
	
</style>

<template>
	<div id="dv-smalltunnel" :style="'left: ' + trueLeft + 'px; top: ' + trueTop + 'px; height: ' + trueHeight + 'px; width: ' + trueWidth + 'px; background-image:url(imgs/p4/d1dd360c-e8a7-44ec-a494-2cb15aa1fa4b.png);' + (parseInt(border) > 0 ? ' border: 1px solid red;' : '')">
		<div class="viewbox" :style="'left: ' + init_viewbox_left + 'px; right: ' + init_viewbox_right + 'px'">
			<div class="defectbox">
				<!-- <dv-img class="defectLine" src="imgs/p4/a69a4ab7-03a3-45fe-beca-ca13355eba7b.png" v-for="item in trueDefects" :style="'width: ' + (trueWidth / 799 * 38) + 'px; left: ' + (item.left + 15) + 'px'"></dv-img> -->
				<div class="defecticons" v-for="(item, index) in trueDefects" :key="index" :style="'width: ' + (trueWidth / 799 * 38) + 'px; left: ' + (item.left + 0) + 'px; z-index: ' + (5000 - index)">
					<dv-img class="iconbg" src="imgs/p4/a69a4ab7-03a3-45fe-beca-ca13355eba7b.png" :style="'width: ' + (trueWidth / 799 * 38) + 'px;'"></dv-img>
					<dv-img v-if="item.position == '全环' || item.position == '拱顶'" class="icon1" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '拱顶左侧'" class="icon2" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '全环' || item.position == '左拱腰'" class="icon3" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '拱顶右侧'" class="icon4" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '全环' || item.position == '右拱腰'" class="icon5" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '全环' || item.position == '左边墙'" class="icon6" src="imgs/p4/4006f592-3e09-49b2-8632-b8684a9f1b4e.png"></dv-img>
					<dv-img v-if="item.position == '全环' || item.position == '右边墙'" class="icon7" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '隧底左侧'" class="icon8" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '隧底'" class="icon9" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
					<dv-img v-if="item.position == '隧底右侧'" class="icon10" src="imgs/p4/bbb7404b-e65c-4f8f-a824-7417e27dffef.png"></dv-img>
				</div>
			</div>
			<div class="codebox">
				<div class="item" :style="'left: 0; width: ' + codeDivWidth + 'px'" v-text="code + startkm + '+' + startm"></div>
				<div class="item" v-for="(item, index) in trueDivCount" :key="index" :style="'left: ' + ((item) * trueDivWidth) + 'px; width: ' + codeDivWidth + 'px'" v-text="codeKMM(item)"></div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as $ from 'jquery'
	import dvImg from './dv-img'

	export default {
		data: function(){
			return {
				Id: 'SmallTunnelComponent',

				showCount: 5,				// 默认显示里程桩号的数量
				codeDivWidth: 80,			// 里程桩号显示框的宽度
				
				init_viewbox_left: 12,		// viewbox初始化left
				init_viewbox_right: 46,		// viewbox 与 父框 的右侧修正边距
				
				initTunnelLength: 100, 		// 隧道初始化长度，默认100米
				initDivLength: 50,			// 默认每隔50米，显示一个里程桩号
				initHeight: 100,			// 默认组件高度100px
				initWidth: 400,				// 默认组件宽度400px
				initLeft: 100,				// 默认组件定位，左边距100px
				initTop: 400,				// 默认组件定位，上边距400px

			}
		},
		// length:    隧道长度，单位米
		// divlength: 隧道每多少米显示一个里程桩号，单位米，默认50米
		// divcount:  可见区域内显示的里程桩号数量，默认5个
		props: ['left', 'top', 'width', 'height', 'border',  'divlength', 'divcount', 'code', 'startkm', 'startm', 'length', 'defects'],
		created() {
			window[this.Id] =  this;
			window.dataV.smallTunnel = this;
		},
		computed: {
			trueLeft(){
				return this.left ? this.left : this.initLeft;
			},
			trueTop(){
				return this.top ? this.top : this.initTop;
			},
			trueHeight(){
				return this.height ? this.height : this.initHeight;
			},
			trueWidth(){
				return this.width ? this.width : this.initWidth;
			},
			trueDivCount(){
				var length = (this.length ? this.length : this.initTunnelLength);
				return Math.floor(length / this.trueDivLength);
			},
			trueShowDivCount(){
				var length = (this.length ? this.length : this.initTunnelLength);
				var count = Math.floor(length / this.trueDivLength);
				var usrCount = this.divcount ? this.divcount : this.showCount - 1;
				if(count < usrCount){
					return count;
				}else{
					return usrCount;
				}
			},
			trueDivLength(){
				return this.divlength ? this.divlength : 50;
			},
			trueDivWidth(){
				var width = this.trueWidth - this.init_viewbox_right - this.codeDivWidth;
				return Math.floor(width / this.trueShowDivCount)
			},
			
			trueDefects(){
				var that = this;
				//
				var defects = this.defects || [];
				var returnValue = [];
				$.each(defects, function(key, item){
					returnValue.push({ 
						position: item.position,
						left: (item.startKiloMetre * 1000 + item.startMetre - that.startkm * 1000 - that.startm) / that.trueDivLength * that.trueDivWidth,
						leftM: item.startKiloMetre * 1000 + item.startMetre - that.startkm * 1000 - that.startm
					})
				})
				return returnValue;
			},
			
			trueScrollWidth(){
				return this.trueDivCount * this.trueDivWidth + this.codeDivWidth - $('#dv-smalltunnel .viewbox').width();
			}
		},
		methods: {
			codeKMM(index){
				var KMM = parseInt(this.startkm) * 1000 + parseInt(this.startm) + parseInt(this.trueDivLength) * index;
				return this.code + Math.floor(KMM / 1000) + '+' + (KMM % 1000);
			},
			
			scrollLeft(percent){
				$('#dv-smalltunnel .viewbox').scrollLeft(this.trueScrollWidth * percent);
			}
		},
		
		mounted(){
		},
		
		components: {
			'dv-img': dvImg
		}
		
	}
</script>
